<template>
    <div class="footerSection">
        <footer class="bg-img">
            <div class="top-footer">
                <div class="sec-logo">
                    <router-link to="/"><img class="logo-footer" src="../../public/assets/images/logo-footer.webp" alt="believe-logo"></router-link>
                </div>
                <!-- <div class="sec-bs"> -->
                <div class="sec-b">
                    <h4 class="footer-head">BELIEVE</h4>
                    <ul class="">
                        <li><router-link to="/about">About Believe</router-link></li>
                        <!-- <li><a href="/about" title="">About Believe</a></li> -->
                        <li><router-link to="/victoria">Meet Victoria</router-link></li>
                        <!-- <li><a href="/victoria" title="">Meet Victoria</a></li> -->
                        <li><router-link to="">Blog</router-link></li>
                        <!-- <li><a href="#" title="">Blog</a></li> -->
                        <li><router-link to="/liveevent">Events</router-link></li>
                        <!-- <li><a href="/liveevent" title="">Events</a></li> -->
                        <li><router-link to="/affiliate">Affiliates</router-link></li>
                        <!-- <li><a href="/affiliate" title="">Affiliates</a></li> -->
                        <li><router-link to="/creators">Creators</router-link></li>
                        <!-- <li><a href="/creators" title="">Creators</a></li> -->
                        <li><router-link to="/partner">Partners</router-link></li>
                        <!-- <li><a href="/partner" title="">Partners</a></li> -->
                    </ul>
                </div>
                <div class="sec-s">
                    <h4 class="footer-head">Support</h4>
                    <ul class="">
                        <li><router-link to="/help" target="_blank">Help</router-link></li>
                        <!-- <li><a href="/help" target="_blank">Help</a></li> -->
                        <li><router-link to="/faq">FAQ</router-link></li>
                        <!-- <li><a href="/faq" title="">FAQ</a></li> -->
                        <li><a href="mailto:support@believehypnosis.app">Contact us</a></li>
                        <!-- <li><a href="#" title="">Contact us</a></li> -->
                        <li><router-link to="/terms">Terms</router-link></li>
                        <!-- <li><a href="/terms" title="">Terms</a></li> -->
                        <li><router-link to="/privacy">Privacy</router-link></li>
                        <!-- <li><a href="/privacy" title="">Privacy</a></li> -->
                        <li><router-link to="/billing">Billing</router-link></li>
                        <!-- <li><a href="/billing" title="">Billing</a></li> -->
                    </ul>
                </div>
                <!-- </div> -->
                <!-- <div class="sec-tf"> -->
                <div class="sec-t">
                    <h4 class="footer-head">Try BELIEVE Free</h4>
                    <ul class="">
                        <li v-if="this.$store.state.user == null" class="mb-3">
                            <router-link v-if="this.$store.state.user == null" class="footer-login-btn" to="/login"
                                title="">
                                login
                            </router-link>
                        </li>
                        <li class="">
                            <span class="app-span">
                                <a aria-label="Read more about Believe" href="#" title="">
                                    <i class="fab fa-apple kamel"></i>
                                </a>
                                <a aria-label="Read more about Believe" href="https://apps.apple.com/us/app/believe-hypnosis/id6466321566" target="_blank"><p class="text-white">Download iOS App</p></a>
                            </span>
                        </li>
                        <li>
                            <span class="app-span">
                                <a aria-label="Read more about Believe" href="https://play.google.com/store/apps/details?id=com.hyptalk.believe" target="_blank" title="">
                                    <i class="fab fa-android kamel"></i>
                                </a>
                                <a aria-label="Read more about Believe" href="https://play.google.com/store/apps/details?id=com.hyptalk.believe" target="_blank"><p class="text-white">Download Android App</p></a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="sec-f">
                    <h4 class="footer-head">Follow</h4>
                    <ul class="follow-icons">
                        <li class="">
                            <a  href="https://www.instagram.com/victoriamgallagher/" target="_blank"><i
                                    class="fab fa-instagram"></i></a>
                        </li>
                        <li>
                            <a  href="https://www.facebook.com/victoriamariegallagher" target="_blank"><i
                                    class="fab fa-facebook"></i></a>
                        </li>
                        <li>
                            <a  href="https://twitter.com/loahypnotist" target="_blank" class="x-logo">
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" width="22px" height="22px" viewBox="0 0 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve"><path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z"/></svg>
                            </a>
                        </li>
                        <li>
                            <a  href="https://www.linkedin.com/in/victoriagallagher" target="_blank"><i
                                    class="fab fa-linkedin"></i></a>
                        </li>
                        <li>
                            <a  href="https://www.youtube.com/hyptalk" target="_blank"><i class="fab fa-youtube"></i></a>
                        </li>
                        <li>
                            <a  href="https://www.tiktok.com/@victoriamgallagher" target="_blank"><i
                                    class="fa-brands fa-tiktok"></i></a>
                        </li>
                    </ul>
                </div>
                <!-- </div> -->
            </div>
            <div class="bottom-footer">
                <div><p>© {{ year }} Hyptalk.com, LLC</p></div>
                <div><p>Contact us: <a href="mailto:support@believehypnosis.app">support@believehypnosis.app</a></p></div>                    
            </div>
        </footer>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  components: { RouterLink },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
};
</script>

<style>
.x-logo svg{
  stroke: #ffffff80;
}
.x-logo:hover svg{
  stroke: #ffffff;
}

</style>
