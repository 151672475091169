export default class UserData {
    constructor(initialData = {}) {
        this.userData = initialData;
    }

    cleanUp() {
        this.userData = {};
    }

    /**
     * Set user data by key.
     * @param {string} key - The key of the user data.
     * @param {string} value - The value of the user data.
     * @param {boolean} [hash=true] - Whether to hash the value.
     */
    async setData(key, value, hash = true) {
        this.userData[key] = hash
            ? await this.hash(value.toLowerCase())
            : value;
        return Promise.resolve();
    }

    hash(string) {
        const utf8 = new TextEncoder().encode(string);
        return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            return hashArray
                .map((bytes) => bytes.toString(16).padStart(2, '0'))
                .join('');
        });
    }

    async setEmail(email) {
        return this.setData('em', email);
    }

    async setPhone(phone) {
        const sanitizedPhone = phone.replace(/[^0-9]/g, '');
        return this.setData('ph', sanitizedPhone);
    }

    async setFirstName(name) {
        return this.setData('fn', name);
    }

    async setLastName(name) {
        return this.setData('ln', name);
    }
    async setDateOfBirth(date) {
        return this.setData('bd', date);
    }

    async setGender(gender) {
        return this.setData('ge', gender, false);
    }

    async setCity(city) {
        return this.setData('ct', city);
    }

    async setState(state) {
        return this.setData('st', state.toLowerCase(), false);
    }

    async setZip(zip) {
        return this.setData('zp', zip);
    }

    async setCountry(country) {
        return this.setData('country', country);
    }

    async setExternalId(id) {
        return this.setData('external_id', id);
    }

    async setClientIpAddress(ip) {
        return this.setData('client_ip_address', ip, false);
    }

    async setClientUserAgent(userAgent) {
        return this.setData('client_user_agent', userAgent, false);
    }

    async setSubscriptionId(subscription) {
        return this.setData('subscription_id', subscription);
    }

    async setFbc(fbc) {
        return this.setData('fbc', fbc, false);
    }

    async setFbp(fbp) {
        return this.setData('fbp', fbp, false);
    }

    async setFbLoginId(fbLoginId) {
        return this.setData('fb_login_id', fbLoginId, false);
    }

    async setLeadId(leadId) {
        return this.setData('lead_id', leadId, false);
    }

    async setAnonId(anonId) {
        return this.setData('anon_id', anonId, false);
    }

    async setMadid(madid) {
        return this.setData('madid', madid, false);
    }

    async setPageId(pageId) {
        return this.setData('page_id', pageId, false);
    }

    async setPageScopedUserId(pageScopedUserId) {
        return this.setData('page_scoped_user_id', pageScopedUserId, false);
    }

    async setCtwaClid(ctwaClid) {
        return this.setData('ctwa_clid', ctwaClid, false);
    }

    async setIgAccountId(igAccountId) {
        return this.setData('ig_account_id', igAccountId, false);
    }

    async setIgSid(igSid) {
        return this.setData('ig_sid', igSid, false);
    }
}
