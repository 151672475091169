<template>
  <div id="app">
    <div>
      <!-- <loading :active="$store.state.isLoading" :can-cancel="false" :is-full-page="true"></loading> -->
      <router-view></router-view>
      <!-- <stripe-card /> -->
    </div>
  </div>
</template>

<script>
// import StripeCard from './components/StripeCard.vue';
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import API from "./config/api";

export default {
  components: {
    // StripeCard
    // Loading,
  },
  created() {
    this.detectFacebookClickLead();
    this.verifyUserToken();
    this.verify();
  },
  mounted() {
    this.getClientInfo();
    const sub_history = localStorage.getItem('sub_history');
    this.$store.state.sub_history = JSON.parse(sub_history);

    // const script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js';
    // script.async = true;
    // script.onload = this.initializeActiveCampaign;

    // this.$nextTick(() => {
    //   document.head.appendChild(script);
    // });
  },
  methods: {
    getClientInfo() {
      if (!sessionStorage.getItem("client_info")) {
        console.log('Get the client\'s IP address and user agent');
        fetch("https://api.ipify.org?format=json")
            .then(response => response.json())
            .then(data => {
              const client_info = {
                ip: data.ip,
                user_agent: navigator.userAgent
              };
              this.$store.state.client_info = client_info;
              sessionStorage.setItem("client_info", JSON.stringify(client_info));
            });
      }
    },

    detectFacebookClickLead(){
      if (this.$route.query.fbclid) {
        this.setCookie('_fbc', this.$route.query.fbclid);
      }
    },
    /**
     * Verifies the user token.
     *
     * This function retrieves the user and token from the local storage.
     * If the user or token is not present, it cleans up the local storage.
     * Otherwise, it makes a POST request to the "/verify-user-token" endpoint
     * with the user's ID and token. If the response says the token is invalid,
     * it cleans up the local storage.
     */
    async verifyUserToken() {
      const user = localStorage.getItem("user");
      const myToken = localStorage.getItem("token");

      if (!user || !myToken) {
        this.cleanupLocalStorage();
        return;
      }

      try {
        const response = await API.post("/verify-user-token", {
          id: JSON.parse(user).id,
          token: myToken,
        });

        if (!response.data.status) {
          this.cleanupLocalStorage();
          this.$store.state.user = null;
        }

        console.log("token resp: ", response);
      } catch (error) {
        console.log(error);
      }
    },

    cleanupLocalStorage() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("promo_code");
      localStorage.removeItem("has_promo");
      localStorage.removeItem("package_type");
    },
    async verify() {
      try {
        const response = await API.get("/verify");
        this.$store.state.user = response.data;
        console.log("res", response.data);
        const sub_history = await API.get("/list-web-subscription");
        this.$store.state.sub_history = sub_history?.data?.subscriptions;
      } catch (error) {
        console.log("not logged in.");
      }
    },
    // initializeActiveCampaign() {
    //   if (typeof window.vgo !== 'undefined') {
    //     window.vgo('setAccount', '8');
    //     window.vgo('setTrackByDefault', true);
    //     window.vgo('process');
    //   }
    // },
  },
};
</script>

<!-- <style>

.main-terms-content {
    padding-top: 0px !important;
}

</style> -->
