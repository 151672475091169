import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";

// import Login from "../pages/login";
import Home from "../pages/HomePage";
import LandingPage from "../pages/LandingPage";
import Register from "../pages/register";
// import ResetPassword from "../pages/ResetPassword";
import Success from "../pages/Success";
import SuccessPlanChanged from "../pages/SuccessPlanChanged";
import NotFound from "../pages/404";
import VerifyPaypal from "../pages/VerifyPaypal";
import SubscriptionCancelInfo from "../pages/SubscriptionCancelInfo";
import ServiceSlider from "../components/ServiceCarouselNew";
// import Payments from "../pages/Payments";
import PromoLink from "../pages/PromoLink";
import Feelings from "../components/feelings";
import TermsCondition from "../pages/TermsCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import BillingDetail from "../pages/BillingDetail";
import SubcriptionDetail from "../pages/SubcriptionDetail";
import HelpandSupport from "../pages/HelpandSupport";
import RefundPolicy from "../pages/RefundPolicy";
import AboutMob from "../pages/AboutMob";
import FaqMob from "../pages/FaqMob";
// import HelpandSupport from '../pages/HelpandSupport';
import ManageMob from "../pages/ManageMob";
import SubsDetailsMob from "../pages/SubsDetailsMob";
import PaymentSuccessfull from "../pages/PaymentSuccessfull";
import PaymentFailed from "../pages/PaymentFailed";
import CCFreeTrial from "../newPages/CCFreeTrial";
import CCYear from "../newPages/CCYear";
import CCYearBill from "../newPages/CCYearBill";
import CCMonPer from "../newPages/CCMonPer";
import CCMonBill from "../newPages/CCMonBill";
import PlanMon from "../newPages/PlanMon";
import SubscriptionForm from "../newPages/SubscriptionForm";
import About from "../pages/About";
import Fourzerofour from "../pages/Fourzerofour";
import Affiliate from "../pages/Affiliate";
import Creators from "../pages/Creators";
import LiveEvent from "../pages/LiveEvent";
import Partner from "../pages/Partner";
import Victoria from "../pages/Victoria";
import Deletion from "../pages/DeletionPolicy";
import { store } from "../store";

const adminguard = (to, from, next) => {
  if (JSON.parse(localStorage.getItem("user")) != null) {
    // next(false);
    next({ path: '/' });
  } else {
    next();
  }
};

const adminGuardInverse = (to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    if (to.path !== '/login') {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    console.log('admin guard inverse');
    console.log(to.path);
    console.log('revecatsubtype store : ', store.state.revcatSubType);
    console.log('revecatsubtype localstorage : ', localStorage.getItem('revcatSubType'));

    let planName = store.state.revcatSubType ?? localStorage.getItem('revcatSubType');

    if (store.state.revcatSubType == "Free" && localStorage.getItem('revcatSubType') == "Yearly") {
      planName = localStorage.getItem('revcatSubType');
    }

    console.log('planName : ', planName);
    if (to.path === '/plans' && planName == "Yearly") {
      next({ path: '/manage' });
    } else {
      next();
    }
  }
};


Vue.use(VueRouter);
Vue.use(store);

const routes = [
  {
    path: "/404",
    component: Fourzerofour,
  },
  {
    path: "/plan-mon",
    component: PlanMon,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/affiliate",
    component: Affiliate,
  },
  {
    path: "/creators",
    component: Creators,
  },
  {
    path: "/liveevent",
    component: LiveEvent,
  },
  {
    path: "/partner",
    component: Partner,
  },
  {
    path: "/victoria",
    component: Victoria,
  },
  {
    path: "/plans",
    component: PlanMon,
    beforeEnter: adminGuardInverse,
  },
  {
    path: "/payment",
    component: SubscriptionForm,
  },
  {
    path: "/success",
    component: PaymentSuccessfull,
  },
  {
    path: "/failed",
    component: PaymentFailed,
  },
  {
    path: "/cc-mon-bill",
    component: CCMonBill,
  },
  {
    path: "/cc-mon-percent",
    component: CCMonPer,
  },
  {
    path: "/cc-free-trial",
    component: CCFreeTrial,
  },
  {
    path: "/cc-year",
    component: CCYear,
  },
  {
    path: "/cc-year-bill",
    component: CCYearBill,
  },
  {
    path: "/refund-policy",
    component: RefundPolicy,
  },
  {
    path: "/subscriptioncancelinfo",
    component: SubscriptionCancelInfo,
  },
  {
    path: "/subscription-mob/:token",
    component: SubsDetailsMob,
  },
  {
    path: "/manage-mob",
    component: ManageMob,
  },
  {
    path: "/help",
    component: HelpandSupport,
  },
  {
    path: "/manage",
    component: SubcriptionDetail,
    // beforeEnter: adminguard,
  },

  {
    path: "/login",
    // component: Login,
    component: () => import('../pages/login'),
    beforeEnter: adminguard,
  },
  {
    path: "/register",
    component: Register,
    beforeEnter: adminguard,
  },
  // {
  //   path: "/reset-password",
  //   component: ResetPassword,
  // },
  {
    path: "/processing",
    component: Success,
  },
  {
    path: "/success-plan-changed",
    component: SuccessPlanChanged,
  },
  {
    path: "/verify-paypal",
    component: VerifyPaypal,
  },
  {
    path: "/",
    component: LandingPage,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/serviceSlider",
    component: ServiceSlider,
  },

  {
    path: "/link",
    component: PromoLink,
  },
  {
    path: "/feelings",
    component: Feelings,
  },
  {
    path: "/terms",
    component: TermsCondition,
  },
  {
    path: "/privacy",
    component: PrivacyPolicy,
  },
  {
    path: "/billing",
    component: BillingDetail,
  },
  {
    path: "/faq",
    component: FaqMob,
  },
  {
    path: "/aboutmob",
    component: AboutMob,
  },
  {
    path: "/deletion-policy",
    component: Deletion,
  },
  
  {
    path: "*",
    component: NotFound
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
