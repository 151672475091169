export default class CustomData {
    constructor(initialData = {}) {
        this.customData = initialData;
    }

    async setData(key, value) {
        this.customData[key] = value;
        return Promise.resolve();
    }

    async setCurrency(currency) {
        await this.setData('currency', currency);
    }

    async setValue(value) {
        await this.setData('value', value);
    }
}