import axios from "axios";
import UserData from "./UserData";
import CustomData from "./CustomData";

class FacebookConversions {
    constructor(pixelId, accessToken) {
        this.pixelId = pixelId || process.env.VUE_APP_FACEBOOK_PIXEL_ID;
        this.accessToken = accessToken || process.env.VUE_APP_FACEBOOK_ACCESS_TOKEN;
        this.eventName = null;
        this.eventSourceUrl = window.location.href;
        this.actionSource = "website";
        this.optOut = false;
        this.userData = new UserData({});
        this.customData = new CustomData({});
    }

    /**
     * Sets the name of the event.
     * @param {string} name - The name of the event.
     */
    setEventName(name) {
        this.eventName = name;
    }

    /**
     * Sets user data.
     * @param {Object} data - User data object.
     */
    async setUserData(data) {
        if (data.email) await this.userData.setEmail(data.email);
        if (data.phone) await this.userData.setPhone(data.phone);
        if (data.firstName) await this.userData.setFirstName(data.firstName);
        if (data.lastName) await this.userData.setLastName(data.lastName);
        if (data.dateOfBirth) await this.userData.setDateOfBirth(data.dateOfBirth);
        if (data.gender) await this.userData.setGender(data.gender);

        if (data.city) await this.userData.setCity(data.city);
        if (data.state) await this.userData.setState(data.state);
        if (data.country) await this.userData.setCountry(data.country);
        if (data.zip) await this.userData.setZip(data.zip);

        if (data.externalId) await this.userData.setExternalId(data.externalId);
        if (data.subscriptionId) await this.userData.setSubscriptionId(data.subscriptionId);
        if (data.fb_login_id) await this.userData.setFbLoginId(data.fb_login_id);
        if (data.lead_id) await this.userData.setLeadId(data.lead_id);
        if (data.anon_id) await this.userData.setAnonId(data.anon_id);
        if (data.madid) await this.userData.setMadid(data.madid);
        if (data.page_id) await this.userData.setPageId(data.page_id);
        if (data.page_scoped_user_id) await this.userData.setPageScopedUserId(data.page_scoped_user_id);
        if (data.ctwa_clid) await this.userData.setCtwaClid(data.ctwa_clid);
        if (data.ig_account_id) await this.userData.setIgAccountId(data.ig_account_id);

        await this.setDefaultUserData();
        return Promise.resolve();
    }

    async setCustomData(data) {
        if (data.currency) await this.customData.setCurrency(data.currency);
        if (data.value >= 0) await this.customData.setValue(data.value);
    }


    /**
     * Sets default user data (for example, user agent and cookies).
     */
    async setDefaultUserData() {
        let client_info = JSON.parse(sessionStorage.getItem('client_info'));
        await this.userData.setClientUserAgent(client_info.user_agent);
        await this.userData.setClientIpAddress(client_info.ip);
        await this.userData.setFbp(this.getCookie("_fbp"));
        await this.userData.setFbc(this.getCookie("_fbc"));
    }

    /**
     * Retrieves the value of a specified cookie.
     * @param {string} name - Cookie name.
     * @returns {string|undefined} - Cookie value.
     */
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    /**
     * Sends the event to Facebook API.
     */
    async sendEvent() {
        if (!this.pixelId || !this.accessToken) {
            console.error("Pixel ID or Access Token not set.");
            return false;
        }

        const url = `https://graph.facebook.com/v21.0/${this.pixelId}/events?access_token=${this.accessToken}`;
        const payload = {
            data: [{
                event_name: this.eventName,
                event_time: Math.floor(Date.now() / 1000),
                action_source: this.actionSource,
                user_data: this.userData.userData,
                custom_data: this.customData.customData,
                event_source_url: this.eventSourceUrl,
                opt_out: this.optOut,
            }]
        };
        console.log(`Sending ${this.eventName} event to Facebook Pixel`);
        try {
            return await axios.post(url, payload);
        } catch (error) {
            console.error(
                `Error sending ${this.eventName} event to Facebook Pixel`,
                error.response?.data || error.message
            );
            return false;
        }
    }

    async sendEventAction(action, userData = [], customData = []) {
        this.userData.cleanUp();
        this.getDefaultUserData()
        await this.setUserData(userData);
        await this.setCustomData(customData);
        this.setEventName(action);
        return await this.sendEvent().then((response) => {
            if( response.status === 200 ) {
                console.log(`Event ${action} sent successfully`);
            }
            return response;
        });
    }

    getDefaultUserData() {
        return {
            client_user_agent: navigator.userAgent,
            fbp: this.getCookie("_fbp"),
            fbc: this.getCookie("_fbc"),
        };
    }
}

export default FacebookConversions;
